import Combo from "../models/combo";
import Product from "../models/product";

export interface CartItem {
    id: string;
    name: string;
    attrs: string[];
    p_id: number;
    attr_ids: string[];
    price: number;
    price_for_ctv: number;
    quantity: number;
    max: number;
    featured_image: string;
    combos: Combo[];
    weight: number;
    width: number;
    height: number;
    length: number;
    store: number;
    available_stores: any;
}

export interface CartState {
    items: CartItem[];
    loading: boolean;
    message: string;
}

export const initialState = {
    items: [],
    loading: false,
    message: "",
};

const addProductToCart = (
    product: CartItem,
    cartState: CartState,
    isCumulated: boolean
) => {
    const updatedItems = [...cartState.items];
    const index = updatedItems.findIndex(
        (item: CartItem) => item.id === product.id
    );
    if (index >= 0) {
        const newQuantity = isCumulated
            ? Number(updatedItems[index].quantity + product.quantity)
            : Number(updatedItems[index].quantity + 1);
        let combos = [...updatedItems[index].combos];
        if (isCumulated && product.combos.length > 0) {
            for (let i = 0; i < product.combos.length; ++i) {
                const newCombo = product.combos[i];
                let existsCombo = combos.find(
                    (combo: Combo) => newCombo.id === combo.id
                );
                if (typeof existsCombo !== "undefined") {
                    existsCombo.related.map((existsProduct: Product) => {
                        let currentQuantity = Number(
                            existsProduct.required_quantity
                        );
                        const newComboProduct = newCombo.related.find(
                            (product: Product) =>
                                product.p_id === existsProduct.p_id
                        );
                        if (typeof newComboProduct !== "undefined") {
                            currentQuantity += Number(
                                newComboProduct.required_quantity
                            );
                        }
                        existsProduct.required_quantity = currentQuantity;
                        return existsProduct;
                    });
                    existsCombo.total += newCombo.total;
                    existsCombo.discount += newCombo.discount;
                } else {
                    combos.push(newCombo);
                }
            }
        }

        updatedItems[index] = {
            ...product,
            quantity: newQuantity,
            combos: combos,
        };
    } else {
        updatedItems.push({ ...product });
    }
    return updatedItems;
};

const removeProductFromCart = (productId: string, cartState: CartState) => {
    const updatedItems = [...cartState.items];
    const index = updatedItems.findIndex(
        (item: CartItem) => item.id === productId
    );
    if (index >= 0) {
        const newQuantity = Number(updatedItems[index].quantity - 1);
        if (newQuantity <= 0) {
            updatedItems.splice(index, 1);
        } else {
            updatedItems[index].quantity = newQuantity;
        }
    }

    return updatedItems;
};

const updateProductStore = (productId: string, cartState: CartState, storeId: any) => {
    const updatedItems = [...cartState.items];
    const index = updatedItems.findIndex(
        (item: CartItem) => item.id === productId
    );
    if (index >= 0) {
        updatedItems[index].store = storeId;
    }

    return updatedItems;
};

const removeComboFromCart = (
    productId: string,
    comboId: number,
    cartState: CartState
) => {
    let currentCartItems = [...cartState.items];
    const product = currentCartItems.find(
        (item: CartItem) => item.id === productId
    );
    if (typeof product !== "undefined") {
        const comboIndex = product.combos.findIndex(
            (item: Combo) => item.id === comboId
        );
        if (comboIndex >= 0) {
            product.combos.splice(comboIndex, 1);
        }
    }
    return currentCartItems;
};

const updateMainProductDimension = (
    product: CartItem,
    productId: string,
    name: string,
    value: number,
    isCombo: boolean,
    cartState: CartState
) => {
    const currentCartItems = [...cartState.items];
    const index = currentCartItems.findIndex(
        (item: CartItem) => item.p_id === Number(productId)
    );

    if (index >= 0) {
        const product = currentCartItems[index];
        currentCartItems[index] = {
            ...product,
            [`${name}`]: Number(value),
        };
    }
    return currentCartItems;
};

export const CartReducer = (cartState: CartState, action: any) => {
    switch (action.type) {
        case "PROCESS":
            return {
                ...cartState,
                loading: true,
                message: "",
            };
        case "ADD":
            return {
                items: addProductToCart(
                    action.payload.item,
                    cartState,
                    action.payload.isCumulated
                ),
                message: `Sản phẩm ${action.payload.item.name} đã được thêm vào giỏ hàng`,
                loading: false,
            };
        case "REMOVE":
            return {
                ...cartState,
                items: removeProductFromCart(action.payload.id, cartState),
            };
        case "DELETE":
            return {
                ...cartState,
                items: cartState.items.filter(
                    (item: CartItem) => item.id != action.payload.id
                ),
            };
        case "REMOVE_COMBO":
            return {
                ...cartState,
                items: removeComboFromCart(
                    action.payload.product_id,
                    action.payload.combo_id,
                    cartState
                ),
            };
        case "UPDATE_PRODUCT_DIMENSION":
            return {
                ...cartState,
                items: updateMainProductDimension(
                    action.payload.item,
                    action.payload.product_id,
                    action.payload.name,
                    action.payload.value,
                    action.payload.isCombo,
                    cartState
                ),
            };
        case "UDPATE_STORE":
            return {
                ...cartState,
                items: updateProductStore(action.payload.id, cartState, action.payload.store),
            };
        case "DELETE_ALL":
            return initialState;
        default:
            return cartState;
    }
};
